.writings,
.essay {
  list-style: none;
  margin: 0;
  padding: 0;
}

.essay {
}

.article-link {
  @include themed {
    border-bottom: 1px solid t("postBorder") !important;
    color: t("bodyText");
  }

  & {
    display: block;
    opacity: 85%;

    &:hover {
      opacity: 100%;
      text-decoration: none;
    }
  }
}

.pagination {
  display: flex;
  list-style: none;
  justify-content: space-between;
  margin: 3rem auto 0 auto;
  padding: 0;
}
