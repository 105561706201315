@use "sass:map";

.navigation {
  align-items: center;
  background-color: transparent;
  display: flex;
  grid-area: nav;
  height: fit-content;
  justify-content: space-between;
  margin: auto 0 auto auto;
  padding: 0;
  position: relative;
  text-transform: uppercase;
  z-index: 2;
}

.navigation-toggle {
  display: none;
}

.navigation-list {
  @include themed {
    background-color: transparent;
    transition: t("themeTransition");
  }

  & {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    height: 100%;
    justify-content: flex-end;
    list-style: none;
    margin: 0;
    padding: 0;
    right: 100%;
    top: 0;
    width: fit-content;
  }
}

@media (width <= 48rem) {
  .navigation {
    @include themed {
      background-color: t("navBackground");
      transition: t("themeTransition");
    }

    & {
      left: 0;
      order: 1;
      position: fixed;
      top: 0;
      z-index: 15;
    }
  }

  .navigation-list {
    @include themed {
      background-color: t("navBackground");
      border-top: 1px solid t("navBorder");
    }

    & {
      flex-direction: column;
      gap: 0;
      justify-content: flex-start;
      padding: 0;
      position: fixed;
      top: 3rem;
      transition: all 350ms ease-in-out;
      width: 100%;
    }
  }

  .is-open {
    left: 0;
  }

  .is-closed {
    left: -100%;
  }
}

@media (prefers-reduced-motion: reduce) {
  .navigation-list {
    transition: none;
  }
}
