@use "sass:map";

.site-header {
  @include themed {
    color: t("bodyHeadings");
  }

  & {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex: 0 0 auto;
    gap: 1rem;
    grid-area: head;
    position: relative;
    width: max-content;
  }
}

.site-name {
  @include themed {
    color: t("siteTitle");
    transition: t("themeTransition");
  }

  & {
    font-family: map.get($fonts, "heading", "font-family");
    font-size: 2.2rem;
    font-weight: map.get($fonts, "heading", "normal");
    margin: 0;
  }
}

@media (width <= 48rem) {
  .site-header {
    height: auto;
    justify-self: start;
    left: 0;
    order: 2;
    top: 0;

    &::after {
      @include themed {
        background-color: t("navBackground");
      }
      content: "";
      display: block;
      height: 3rem;
      left: 0;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 10;
    }
  }

  .site-name {
    align-items: flex-start;
    font-size: 1.95rem;
    justify-content: flex-end;
    text-align: right;
  }
}
